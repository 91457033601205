<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <div class="col-12 text-center">
            <label>{{ $t("dataOfAttendance") }}</label>
          </div>
          <div class="form-label-group col-md-6">
            <b-form-datepicker
              v-model="employeeData.attendedDate"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                weekday: 'short',
              }"
              v-bind="labels[language] || {}"
              :placeholder="$t('date')"
              :locale="language"
              today-button
              reset-button
              class="form-control"
            ></b-form-datepicker>
          </div>
          <div class="form-label-group col-md-6">
            <b-form-timepicker
              v-model="employeeData.attendedTime"
              v-bind="labels[language] || {}"
              :placeholder="$t('time')"
              :locale="language"
              now-button
              reset-button
              no-close-button
            ></b-form-timepicker>
          </div>
          <TextArea
            :className="'col-12'"
            :id="'attendedNote'"
            :value="employeeData.attendedNote"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="employeeData.attendedNote = $event"
          />

          <div class="col-12 text-center">
            <label>{{ $t("dataOfDeparture") }}</label>
          </div>
          <div class="form-label-group col-md-6">
            <b-form-datepicker
              v-model="employeeData.departureDate"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                weekday: 'short',
              }"
              v-bind="labels[language] || {}"
              :placeholder="$t('date')"
              :locale="language"
              today-button
              reset-button
              class="form-control"
            ></b-form-datepicker>
          </div>
          <div class="form-label-group col-md-6">
            <b-form-timepicker
              v-model="employeeData.departureTime"
              v-bind="labels[language] || {}"
              :placeholder="$t('time')"
              :locale="language"
              now-button
              reset-button
              no-close-button
            ></b-form-timepicker>
          </div>
          <TextArea
            :className="'col-12'"
            :id="'departureNote'"
            :value="employeeData.departureNote"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="employeeData.departureNote = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateEmployeeAttendance"
        >
          {{ submitName }}
        </button>
        <router-link
          :to="'/attendance-employee/' + employeeData.employeeToken"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import TextArea from "@/components/general/TextArea.vue";

export default {
  name: "EmployeeAttendanceForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,

      labels: {
        ar: {
          weekdayHeaderFormat: "narrow",
          labelPrevDecade: "العقد السابق",
          labelPrevYear: "العام السابق",
          labelPrevMonth: "الشهر السابق",
          labelCurrentMonth: "الشهر الحالي",
          labelNextMonth: "الشهر المقبل",
          labelNextYear: "العام المقبل",
          labelNextDecade: "العقد القادم",
          labelToday: "اليوم",
          labelSelected: "التاريخ المحدد",
          labelNoDateSelected: "لم يتم اختيار تاريخ",
          labelCalendar: "التقويم",
          labelNav: "الملاحة التقويم",
          labelHelp: "استخدم مفاتيح المؤشر للتنقل في التواريخ",
          labelTodayButton: "اليوم",
          labelResetButton: "حذف",

          labelHours: "ساعات",
          labelMinutes: "الدقائق",
          labelSeconds: "ثواني",
          labelAmpm: "صباحا مساء",
          labelAm: "ص",
          labelPm: "م",
          labelIncrement: "زيادة",
          labelDecrement: "إنقاص",
          // labelSelected: "الوقت المحدد",
          labelNoTimeSelected: "لم يتم اختيار وقت",
          labelCloseButton: "إغلاق",
          labelNowButton: "الآن",
        },
      },
    };
  },
  props: ["employeeData", "submitName"],
  methods: {
    async addOrUpdateEmployeeAttendance() {
      this.$emit("addOrUpdateEmployeeAttendance");
    },
    showMsg(msg = "", isSuccess = false) {
      let variant = isSuccess ? "success" : "danger";
      this.$bvToast.toast(`${msg}`, {
        title: this.$t("alert"),
        variant: variant,
        toaster: "b-toaster-bottom-center",
        autoHideDelay: 5000,
      });
    },
  },
  async created() {},
};
</script>

<style scoped; lang="scss"></style>
