import store from "../store";

const lang = localStorage.getItem("userLanguage") || "ar";
const theme = localStorage.getItem("userTheme") || "light";
const rootStyle = document.documentElement.style;

export const ifAuthenticated = (to, from, next) => {
  if (store.getters.userData) {
    next({ path: "/employees" });
  } else {
    next();
  }
};
export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.userData) {
    next({ path: "/" });
  } else {
    next();
  }
};

export const validNumber = (number) => {
  var re = /^[0-9]*$/;
  return re.test(number);
};
export const validEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const date2slash = (date) => {
  if (date.indexOf("-") > -1) return date.replace(/-/g, "/");
  else return date;
};
export const date2dash = (date) => {
  if (date.indexOf("/") > -1) return date.replace(/\//g, "-");
  else return date;
};
export const timeTo12 = (time = "") => {
  if (time.length > 1) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
    time[0] = time[0] < 10 ? "0" + time[0] : time[0];
    time[3] = "";
    return time.join("");
  }
  return time;
};
export const timeTo24 = (time = "") => {
  if (time.length > 1) {
    const [theTime, modifier] = time.split(" ");
    let [hours, minutes] = theTime.split(":");

    if (hours == "12") {
      hours = "00";
    }
    if (modifier == "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }
  return time;
};
export const timeToAr = (time) => {
  if (time.indexOf("AM") > -1) return time.replace(/AM/g, "ص");
  else if (time.indexOf("PM") > -1) return time.replace(/PM/g, "م");
  else return time;
};
export const timeToEn = (time) => {
  if (time.indexOf("ص") > -1) return time.replace(/ص/g, "AM");
  else if (time.indexOf("م") > -1) return time.replace(/م/g, "PM");
  else return time;
};
export const timeToLang = (time, lang = "ar") => {
  if (lang == "ar" && time.indexOf("AM") > -1) return time.replace(/AM/g, "ص");
  else if (lang == "ar" && time.indexOf("PM") > -1)
    return time.replace(/PM/g, "م");
  else if (lang == "en" && time.indexOf("ص") > -1)
    return time.replace(/ص/g, "AM");
  else if (lang == "en" && time.indexOf("م") > -1)
    return time.replace(/م/g, "PM");
  else return time;
};

export const setDataMultiLang = (lang, dataAr, dataEn) => {
  if (lang == "en") return dataEn;
  else return dataAr;
};

if (lang == "en") {
  rootStyle.setProperty("--position-start", "left");
  rootStyle.setProperty("--position-end", "right");
  rootStyle.setProperty("--direction", "ltr");
  rootStyle.setProperty("--font-family", "sans-serif");
  rootStyle.setProperty("--display-btn-en", "none");
  rootStyle.setProperty("--display-btn-ar", "block");
  rootStyle.setProperty("--position-left-status", "auto");
  rootStyle.setProperty("--position-right-status", "0");
  rootStyle.setProperty("--fab-container-left", "auto");
  rootStyle.setProperty("--fab-container-right", "15px");
} else {
  rootStyle.setProperty("--position-start", "right");
  rootStyle.setProperty("--position-end", "left");
  rootStyle.setProperty("--direction", "rtl");
  rootStyle.setProperty("--font-family", "arabic");
  rootStyle.setProperty("--display-btn-en", "block");
  rootStyle.setProperty("--display-btn-ar", "none");
  rootStyle.setProperty("--position-left-status", "0");
  rootStyle.setProperty("--position-right-status", "auto");
  rootStyle.setProperty("--fab-container-left", "15px");
  rootStyle.setProperty("--fab-container-right", "auto");
}

if (theme == "dark") {
  rootStyle.setProperty("--color-top", "#011F26");
  rootStyle.setProperty("--color-middel", "#01151A");
  rootStyle.setProperty("--color-bg-main", "#1A1D21");
  rootStyle.setProperty("--color-bg-page-content", "#1A1D21");
} else {
  rootStyle.setProperty("--color-top", "#073642");
  rootStyle.setProperty("--color-middel", "#002B36");
  rootStyle.setProperty("--color-bg-main", "#FFFFFF");
  rootStyle.setProperty("--color-bg-page-content", "#FFFFFF");
}
