<template>
  <nav>
    <PreLoader v-if="isLoading" />
    <input type="checkbox" id="show-menu" @click="changeMenuStatus" />
    <label for="show-menu" class="menu-icon"><i class="fa fa-bars"></i></label>
    <!-- <span class="logo-icon" :title="$t('projectName')">
      <img src="@/assets/images/logo.png" class="logo" />
    </span> -->
    <div class="page-name">
      <!-- <img src="@/assets/images/homepage.svg" /> -->
      <img :src="require('@/assets/images/' + pageIcon)" />
      <h3>{{ pageName }}</h3>
    </div>
    <div class="user-icon" v-if="userData">
      <b-dropdown id="dropdown-user">
        <template #button-content>
          <img
            :src="employeeImage"
            :onerror="`this.src='${defaultImg}'`"
            alt="images"
          />
        </template>
        <div class="text-center">
          <img
            :src="employeeImage"
            :onerror="`this.src='${defaultImg}'`"
            alt="images"
            class="img-profile"
          />
          <b-dropdown-item>{{ employeeName }}</b-dropdown-item>
        </div>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-if="language == 'en'" @click="changeLanguage('ar')">
          <img src="@/assets/images/lang-ar.svg" />
          العربية
        </b-dropdown-item>
        <b-dropdown-item v-if="language == 'ar'" @click="changeLanguage('en')">
          <img src="@/assets/images/lang-en.svg" />
          English
        </b-dropdown-item>
        <!-- <b-dropdown-item v-if="theme == 'dark'" @click="changeTheme('light')">
          <img src="@/assets/images/mode-light.svg" />
          {{ $t("lightMode") }}
        </b-dropdown-item>
        <b-dropdown-item v-if="theme == 'light'" @click="changeTheme('dark')">
          <img src="@/assets/images/mode-dark.svg" />
          {{ $t("darkMode") }}
        </b-dropdown-item> -->
        <b-dropdown-item v-b-modal.UserChangePassword>
          <img src="@/assets/images/changePassword.svg" />
          {{ $t("changePassword") }}
        </b-dropdown-item>
        <b-dropdown-item v-b-modal.UserLogOut>
          <img src="@/assets/images/logout.svg" />
          {{ $t("logout") }}
        </b-dropdown-item>
        <!-- <b-dropdown-item active>Active action</b-dropdown-item> -->
      </b-dropdown>
    </div>

    <UserChangePassword v-on:logout="logout" />
    <UserLogOut v-on:logout="logout" />
  </nav>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import { BASE_URL, DEFAULT_IMG } from "@/utils/constants";
import { mapGetters } from "vuex";
import UserChangePassword from "@/components/user/UserChangePassword.vue";
import UserLogOut from "@/components/user/UserLogOut.vue";

export default {
  name: "Navbar",
  components: {
    PreLoader,
    UserChangePassword,
    UserLogOut,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      theme: localStorage.getItem("userTheme") || "light",
      checked: false,
      pageName: this.$t("homePage"),
      pageIcon: "homepage.svg",
      defaultImg: DEFAULT_IMG,
    };
  },
  methods: {
    logout() {
      this.isLoading = true;
      this.$store.dispatch("updateUserData", null);
      this.$router.push("/").catch(() => {});
    },
    changeLanguage(lang) {
      this.isLoading = true;
      localStorage.setItem("userLanguage", lang);
      window.location.reload();
    },
    changeTheme(theme) {
      this.isLoading = true;
      localStorage.setItem("userTheme", theme);
      window.location.reload();
    },
    changeMenuStatus() {
      this.$emit("changeMenuStatus");
    },
    getCurrentPage(path) {
      if (path == "/") {
        this.pageName = this.$t("homePage");
        this.pageIcon = "homepage.svg";
      } else if (path.indexOf("/employee-slices") == 0) {
        this.pageName = this.$t("slices");
        this.pageIcon = "slices.svg";
      } else if (path.indexOf("/add-employee-slice") == 0) {
        this.pageName = this.$t("addSlice");
        this.pageIcon = "slices.svg";
      } else if (path.indexOf("/employees") == 0) {
        this.pageName = this.$t("theEmployees");
        this.pageIcon = "employees.svg";
      } else if (path.indexOf("/add-employee") == 0) {
        this.pageName = this.$t("employeeAdd");
        this.pageIcon = "addUser.svg";
      } else if (path.indexOf("/edit-employee") == 0) {
        this.pageName = this.$t("employeeEdit");
        this.pageIcon = "pencil.svg";
      } else if (path.indexOf("/attendance-employee") == 0) {
        this.pageName = this.$t("attendanceHistory");
        this.pageIcon = "attendance.svg";
      } else if (path.indexOf("/add-attendance-employee") == 0) {
        this.pageName = this.$t("attendanceAdd");
        this.pageIcon = "plus.svg";
      } else if (path.indexOf("/edit-attendance-employee") == 0) {
        this.pageName = this.$t("attendanceEdit");
        this.pageIcon = "pencil.svg";
      } else if (path.indexOf("/movement-employee") == 0) {
        this.pageName = this.$t("employeeMovement");
        this.pageIcon = "address.svg";
      } else if (path.indexOf("/add-movement-employee") == 0) {
        this.pageName = this.$t("movementAdd");
        this.pageIcon = "plus.svg";
      } else if (path.indexOf("/edit-movement-employee") == 0) {
        this.pageName = this.$t("movementEdit");
        this.pageIcon = "pencil.svg";
      } else if (path.indexOf("/employee-movement-media") == 0) {
        this.pageName = this.$t("media");
        this.pageIcon = "media.svg";
      } else if (path.indexOf("/attendance-history") == 0) {
        this.pageName = this.$t("attendanceAndDepartureHistory");
        this.pageIcon = "attendanceHistory.svg";
      } else if (path.indexOf("/add-attendance") == 0) {
        this.pageName = this.$t("checkIn");
        this.pageIcon = "add-attendance.svg";
      } else if (path.indexOf("/add-departure") == 0) {
        this.pageName = this.$t("checkOut");
        this.pageIcon = "add-departure.svg";
      } else if (path.indexOf("/points") == 0) {
        this.pageName = this.$t("points");
        this.pageIcon = "points.svg";
      } else if (path.indexOf("/employee-points") == 0) {
        this.pageName = this.$t("employeePoints");
        this.pageIcon = "points.svg";
      } else if (path.indexOf("/reports") == 0) {
        this.pageName = this.$t("reports");
        this.pageIcon = "reports.svg";
      } else if (path.indexOf("/privilege") == 0) {
        this.pageName = this.$t("privilege");
        this.pageIcon = "privilege.svg";
      } else if (path.indexOf("/settings-general") == 0) {
        this.pageName = this.$t("settings-general");
        this.pageIcon = "settings-general.svg";
      } else if (path.indexOf("/settings-additional") == 0) {
        this.pageName = this.$t("settings-additional");
        this.pageIcon = "settings-additional.svg";
      } else if (path.indexOf("/sectors") == 0) {
        this.pageName = this.$t("sectors");
        this.pageIcon = "sectors.svg";
      } else if (path.indexOf("/jobs") == 0) {
        this.pageName = this.$t("jobs");
        this.pageIcon = "jobs.svg";
      } else if (path.indexOf("/qualifications") == 0) {
        this.pageName = this.$t("qualifications");
        this.pageIcon = "qualifications.svg";
      }
    },
  },
  computed: {
    ...mapGetters(["userData"]),
    employeeName: function () {
      if (this.userData) {
        return this.language == "ar"
          ? this.userData.employeePersonalData.employeeNameAr
          : this.userData.employeePersonalData.employeeNameEn;
      } else {
        return null;
      }
    },
    employeeImage: function () {
      return BASE_URL + this.userData.employeePersonalData.employeeImagePath;
    },
  },
  // async created() {
  //   this.employeeProfileData = JSON.parse(
  //     localStorage.getItem("employeeProfileData")
  //   );
  //   console.log(this.employeeProfileData);
  // },
  watch: {
    $route(to) {
      this.getCurrentPage(to.path);
    },
  },
  async created() {
    this.getCurrentPage(this.$route.path);
  },
};
</script>

<style lang="scss">
#dropdown-user button {
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: inherit;
  position: relative;
  border: initial;
  // top: -6px;
  // left: 6px;
}
#dropdown-user button::after {
  border: initial;
}
// #dropdown-user button::after {
//   background-image: url("../../assets/images/man.svg");
//   background-size: 35px;
//   display: inline-block;
//   width: 35px;
//   height: 35px;
//   content: "";
// }
#dropdown-user ul {
  width: 220px;
  // transform: translate3d(0px, 29px, 0px) !important;
  // transform: translate3d(-190px, 29px, 0px) !important;
  // right: var(--position-right-status) !important;
  // left: var(--position-left-status) !important;
}
.user-icon .dropdown-menu li img {
  width: 28px;
  height: 28px;
  border-radius: inherit;
  position: absolute;
  right: 6px;
}
.user-icon .img-profile {
  width: 150px;
  height: initial;
  min-height: 150px;
  max-height: 190px;
  border-radius: inherit;
  margin-bottom: 1rem;
}
</style>
