<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-12">
            <b-form-select
              v-model="privilegeData.employeeToken"
              :options="employeeTokenOptions"
              @change="getUserPrivilege()"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/man.svg" />
          </div>
        </div>
      </div>

      <div v-if="privilegeData.employeeToken">
        <UsersPrivilegeEmployees
          :usersPrivilegeEmployees="privilegeData.usersPrivilegeEmployees"
        />
        <UsersPrivilegeEmployeesAttendance
          :usersPrivilegeEmployeesAttendance="
            privilegeData.usersPrivilegeEmployeesAttendance
          "
        />

        <UsersPrivilegeEmployeesMovement
          :usersPrivilegeEmployeesMovement="
            privilegeData.usersPrivilegeEmployeesMovement
          "
        />
        <UsersPrivilegeEmployeesMovementMedai
          :usersPrivilegeEmployeesMovementMedai="
            privilegeData.usersPrivilegeEmployeesMovementMedai
          "
        />

        <UsersPrivilegeUsers
          :usersPrivilegeUsers="privilegeData.usersPrivilegeUsers"
        />
        <UsersPrivilegeEmployeesSettlementMovement
          :usersPrivilegeEmployeesSettlementMovement="
            privilegeData.usersPrivilegeEmployeesSettlementMovement
          "
        />

        <UserPrivillegBranchesSettings
          :userPrivillegBranchesSettings="
            privilegeData.userPrivillegBranchesSettings
          "
        />
        <UsersPrivilegAdditionalSettings
          :usersPrivilegAdditionalSettings="
            privilegeData.usersPrivilegAdditionalSettings
          "
        />

        <UsersPirivilegeEmployeesMoveSlides
          :usersPirivilegeEmployeesMoveSlides="
            privilegeData.usersPirivilegeEmployeesMoveSlides
          "
        />

        <div class="form-footer">
          <button
            name="submit"
            type="submit"
            class="btn btn-submit"
            @click.prevent="updateUserPrivilege"
          >
            {{ $t("edit") }}
          </button>
          <router-link to="/employees" class="btn btn-cancel">
            {{ $t("cancel") }}
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import axios from "axios";
import UsersPrivilegeEmployees from "@/components/privilege/UsersPrivilegeEmployees.vue";
import UsersPrivilegeUsers from "@/components/privilege/UsersPrivilegeUsers.vue";
import UsersPrivilegeEmployeesAttendance from "@/components/privilege/UsersPrivilegeEmployeesAttendance.vue";
import UsersPrivilegeEmployeesMovement from "@/components/privilege/UsersPrivilegeEmployeesMovement.vue";
import UsersPrivilegeEmployeesMovementMedai from "@/components/privilege/UsersPrivilegeEmployeesMovementMedai.vue";
import UserPrivillegBranchesSettings from "@/components/privilege/UserPrivillegBranchesSettings.vue";
import UsersPrivilegeEmployeesSettlementMovement from "@/components/privilege/UsersPrivilegeEmployeesSettlementMovement.vue";
import UsersPrivilegAdditionalSettings from "@/components/privilege/UsersPrivilegAdditionalSettings.vue";
import UsersPirivilegeEmployeesMoveSlides from "@/components/privilege/UsersPirivilegeEmployeesMoveSlides.vue";

export default {
  name: "PrivilegeForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    UsersPrivilegeEmployees,
    UsersPrivilegeUsers,
    UsersPrivilegeEmployeesAttendance,
    UsersPrivilegeEmployeesMovement,
    UsersPrivilegeEmployeesMovementMedai,
    UserPrivillegBranchesSettings,
    UsersPrivilegeEmployeesSettlementMovement,
    UsersPrivilegAdditionalSettings,
    UsersPirivilegeEmployeesMoveSlides,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      privilegeData: {
        employeeToken: "",
        usersPrivilegeEmployees: {
          employeesView: false,
          employeesAdd: false,
          employeesUpdate: false,
          employeesArchive: false,
          employeesDelete: false,
          employeesRestore: false,
        },
        usersPrivilegeUsers: {
          usersPrivilegeView: false,
          usersPrivilegeUpdate: false,
          usersLogView: false,
        },
        usersPrivilegeEmployeesAttendance: {
          employeesAttendanceViewSelf: false,
          employeesAttendanceViewAll: false,
          employeesAttendanceAttendanceManual: false,
          employeesAttendanceAttendanceList: false,
          employeesAttendanceDepatureList: false,
          employeesAttendanceUpdate: false,
          employeesAttendanceDelete: false,
          employeesAttendanceAttendedSelf: false,
          employeesAttendanceDepartureSelf: false,
        },
        usersPrivilegeEmployeesMovement: {
          employeesMovementViewSelf: false,
          employeesMovementViewAll: false,
          employeesMovementUserMoveSelf: false,
          employeesMovementAddManual: false,
          employeesMovementUpdate: false,
          employeesMovementDelete: false,
        },
        usersPrivilegeEmployeesMovementMedai: {
          employeesMovementMediaView: false,
          employeesMovementMediaAdd: false,
          employeesMovementMediaUpdate: false,
          employeesMovementMediaArchive: false,
          employeesMovementMediaRestore: false,
          employeesMovementMediaDelete: false,
        },
        userPrivillegBranchesSettings: {
          branchesSettingesView: false,
          branchesSettingesAdd: false,
          branchesSettingesUpdate: false,
          branchesSettingesArchive: false,
          branchesSettingesRestore: false,
          branchesSettingesDelete: false,
        },
        usersPrivilegeEmployeesSettlementMovement: {
          employeesSettlementMovementView: false,
          employeesSettlementMovementViewAll: false,
          employeesSettlementMovementViewSelf: false,
          employeesSettlementMovementAdd: false,
          employeesSettlementMovementUpdate: false,
          employeesSettlementMovementDelete: false,
        },
        usersPrivilegAdditionalSettings: {
          additionalSettingsView: false,
          additionalSettingsAdd: false,
          additionalSettingsUpdate: false,
          additionalSettingsArchive: false,
          additionalSettingsRestore: false,
          additionalSettingsDelete: false,
        },
        usersPirivilegeEmployeesMoveSlides: {
          employeesMoveSlidesView: false,
          employeesMoveSlidesViewSelf: false,
          employeesMoveSlidesAdd: false,
          employeesMoveSlidesUpdate: false,
          employeesMoveSlidesArchive: false,
          employeesMoveSlidesRestore: false,
          employeesMoveSlidesDelete: false,
        },
      },

      employeeTokenOptions: [],
    };
  },
  methods: {
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];
      this.employeeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployee"),
      });
      try {
        const response = await axios.get(
          `/Employees/GetDialogOfEmployees?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeesData = null;
      }
      this.isLoading = false;
    },
    async getUserPrivilege() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `/UsersPrivilege//GetUserPrivilege?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&employeeToken=${this.privilegeData.employeeToken}`
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.privilegeData.usersPrivilegeEmployees =
            response.data.userPrivilegeData.usersPrivilegeEmployees;
          this.privilegeData.usersPrivilegeUsers =
            response.data.userPrivilegeData.usersPrivilegeUsers;
          this.privilegeData.usersPrivilegeEmployeesAttendance =
            response.data.userPrivilegeData.usersPrivilegeEmployeesAttendance;
          this.privilegeData.usersPrivilegeEmployeesMovement =
            response.data.userPrivilegeData.usersPrivilegeEmployeesMovement;
          this.privilegeData.usersPrivilegeEmployeesMovementMedai =
            response.data.userPrivilegeData.usersPrivilegeEmployeesMovementMedai;
          this.privilegeData.userPrivillegBranchesSettings =
            response.data.userPrivilegeData.userPrivillegBranchesSettings;
          this.privilegeData.usersPrivilegeEmployeesSettlementMovement =
            response.data.userPrivilegeData.usersPrivilegeEmployeesSettlementMovement;
          this.privilegeData.usersPrivilegAdditionalSettings =
            response.data.userPrivilegeData.usersPrivilegAdditionalSettings;
          this.privilegeData.usersPirivilegeEmployeesMoveSlides =
            response.data.userPrivilegeData.usersPirivilegeEmployeesMoveSlides;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeesData = null;
      }
      this.isLoading = false;
    },
    async updateUserPrivilege() {
      this.isLoading = true;
      const data = {
        usersPersonalData: {
          language: this.language,
          userAuthorizeToken: this.userAuthorizeToken,
          employeeToken: this.privilegeData.employeeToken,
        },
        usersPrivilegeEmployees: this.privilegeData.usersPrivilegeEmployees,
        usersPrivilegeUsers: this.privilegeData.usersPrivilegeUsers,
        usersPrivilegeEmployeesAttendance:
          this.privilegeData.usersPrivilegeEmployeesAttendance,
        usersPrivilegeEmployeesMovement:
          this.privilegeData.usersPrivilegeEmployeesMovement,
        usersPrivilegeEmployeesMovementMedai:
          this.privilegeData.usersPrivilegeEmployeesMovementMedai,
        userPrivillegBranchesSettings:
          this.privilegeData.userPrivillegBranchesSettings,
        usersPrivilegeEmployeesSettlementMovement:
          this.privilegeData.usersPrivilegeEmployeesSettlementMovement,
        usersPrivilegAdditionalSettings:
          this.privilegeData.usersPrivilegAdditionalSettings,
        usersPirivilegeEmployeesMoveSlides:
          this.privilegeData.usersPirivilegeEmployeesMoveSlides,
      };

      try {
        const response = await axios.post(
          `/UsersPrivilege//UpdateUserPrivilege`,
          data
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          // this.privilegeData = {};
          // this.$router.push("/privilege");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    showMsg(msg = "", isSuccess = false) {
      let variant = isSuccess ? "success" : "danger";
      this.$bvToast.toast(`${msg}`, {
        title: this.$t("alert"),
        variant: variant,
        toaster: "b-toaster-bottom-center",
        autoHideDelay: 5000,
      });
    },
  },
  async created() {
    this.getDialogOfEmployees();
  },
};
</script>

<style scoped lang="scss"></style>
