// export const BASE_URL = "https://api-pem.v1.1.premcoprecast.com/";
export const BASE_URL =
  "https://api-employeemovemevt-demo-v1.0.premcoprecast.com/";
// export const BASE_URL = "https://api-premco-v1.adminssw.com/";
// export const BASE_URL = "https://api-employeesmovement-v1.refitegypt.com/";
// export const BASE_URL = "https://192.168.1.2:80/";

export const DEFAULT_IMG = BASE_URL + "/_DefaultFiles/employee.png";
export const STATUS = {
  SUCCESS: 200, // success operation
  INVALID_TOKEN: 498, //invalid token
  CATCH: 500, // catch
  NO_CONTENT: 204, // no data
  ERROR: 406, // validation
  VERSION: 306, // error version
};
export const PAGE_SIZE = 16;
