import Vue from "vue";
import VueRouter from "vue-router";
import { ifAuthenticated, ifNotAuthenticated } from "../utils/functions";

import Home from "../views/home/Home.vue";
import Login from "../views/user/Login.vue";
import ForgetPassword from "../views/user/ForgetPassword.vue";
// import Test from "../views/user/Test.vue";

import Employees from "../views/employees/Employees.vue";
import EmployeeAdd from "../views/employees/EmployeeAdd.vue";
import EmployeeEdit from "../views/employees/EmployeeEdit.vue";

import EmployeeAttendance from "../views/employeesAttendance/EmployeeAttendance.vue";
import EmployeeAttendanceAdd from "../views/employeesAttendance/EmployeeAttendanceAdd.vue";
import EmployeeAttendanceEdit from "../views/employeesAttendance/EmployeeAttendanceEdit.vue";

import EmployeeMovement from "../views/employeesMovement/EmployeeMovement.vue";
import EmployeeMovementAdd from "../views/employeesMovement/EmployeeMovementAdd.vue";
import EmployeeMovementEdit from "../views/employeesMovement/EmployeeMovementEdit.vue";

import EmployeeMovementMedia from "../views/employeesMovementMedia/EmployeeMovementMedia.vue";
import EmployeeMovementMediaInfo from "../views/employeesMovementMedia/EmployeeMovementMediaInfo.vue";

import AttendanceHistory from "../views/attendance/AttendanceHistory.vue";
import AttendanceAttend from "../views/attendance/AttendanceAttend.vue";
import AttendanceDeparture from "../views/attendance/AttendanceDeparture.vue";

import Points from "../views/points/Points.vue";
import EmployeePoints from "../views/points/EmployeePoints.vue";

import EmployeeSlices from "../views/slices/EmployeeSlices.vue";
import EmployeeSlicesAdd from "../views/slices/EmployeeSlicesAdd.vue";

import Reports from "../views/reports/Reports.vue";

import Privilege from "../views/privilege/Privilege.vue";

import SettingsGeneral from "../views/settings/settingsGeneral/SettingsGeneral.vue";
import SettingsGeneralAdd from "../views/settings/settingsGeneral/SettingsGeneralAdd.vue";

import SettingsAdditional from "../views/settings/settingsAdditional/SettingsAdditional.vue";

import Sectors from "../views/settings/settingsAdditional/sectors/Sectors.vue";
import SectorsAdd from "../views/settings/settingsAdditional/sectors/SectorsAdd.vue";
import SectorsEdit from "../views/settings/settingsAdditional/sectors/SectorsEdit.vue";

import Jobs from "../views/settings/settingsAdditional/jobs/Jobs.vue";
import JobsAdd from "../views/settings/settingsAdditional/jobs/JobsAdd.vue";
import JobsEdit from "../views/settings/settingsAdditional/jobs/JobsEdit.vue";

import Qualifications from "../views/settings/settingsAdditional/qualifications/Qualifications.vue";
import QualificationsAdd from "../views/settings/settingsAdditional/qualifications/QualificationsAdd.vue";
import QualificationsEdit from "../views/settings/settingsAdditional/qualifications/QualificationsEdit.vue";
import About from "../views/general/About.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: ForgetPassword,
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: "/test",
  //   name: "Test",
  //   component: Test,
  // },
  {
    path: "/about",
    name: "About",
    component: About,
  },

  {
    path: "/employees",
    name: "Employees",
    component: Employees,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee",
    name: "EmployeeAdd",
    component: EmployeeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-employee/:employeeToken",
    name: "EmployeeEdit",
    component: EmployeeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/attendance-employee/:employeeToken",
    name: "EmployeeAttendance",
    component: EmployeeAttendance,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-attendance-employee/:employeeToken",
    name: "EmployeeAttendanceAdd",
    component: EmployeeAttendanceAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-attendance-employee/:employeeToken/:attendanceToken",
    name: "EmployeeAttendanceEdit",
    component: EmployeeAttendanceEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/movement-employee/:attendanceToken",
    name: "EmployeeMovement",
    component: EmployeeMovement,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-movement-employee/:attendanceToken",
    name: "EmployeeMovementAdd",
    component: EmployeeMovementAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-movement-employee/:attendanceToken/:movementToken",
    name: "EmployeeMovementEdit",
    component: EmployeeMovementEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-movement-media/:movementToken",
    name: "EmployeeMovementMedia",
    component: EmployeeMovementMedia,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/employee-movement-media-info/:movementMediaToken",
    name: "EmployeeMovementMediaInfo",
    component: EmployeeMovementMediaInfo,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/attendance-history",
    name: "AttendanceHistory",
    component: AttendanceHistory,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-attendance",
    name: "AttendanceAttend",
    component: AttendanceAttend,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-departure",
    name: "AttendanceDeparture",
    component: AttendanceDeparture,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/points",
    name: "Points",
    component: Points,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/employee-points/:employeeToken",
    name: "EmployeePoints",
    component: EmployeePoints,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-slices/:employeeToken",
    name: "EmployeeSlices",
    component: EmployeeSlices,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee-slice/:employeeToken",
    name: "EmployeeSlicesAdd",
    component: EmployeeSlicesAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/privilege",
    name: "Privilege",
    component: Privilege,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/settings-general",
    name: "SettingsGeneral",
    component: SettingsGeneral,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-settings-general",
    name: "SettingsGeneralAdd",
    component: SettingsGeneralAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/settings-additional",
    name: "SettingsAdditional",
    component: SettingsAdditional,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/sectors",
    name: "Sectors",
    component: Sectors,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-sector",
    name: "SectorsAdd",
    component: SectorsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-sector/:sectorToken",
    name: "SectorsEdit",
    component: SectorsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-job",
    name: "JobsAdd",
    component: JobsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-job/:jobToken",
    name: "JobsEdit",
    component: JobsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/qualifications",
    name: "Qualifications",
    component: Qualifications,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-qualification",
    name: "QualificationsAdd",
    component: QualificationsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-qualification/:qualificationToken",
    name: "QualificationsEdit",
    component: QualificationsEdit,
    beforeEnter: ifNotAuthenticated,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
