<template>
  <ul>
    <!-- <li>
      <router-link to="/" :class="currentPage == 'home' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/homepage.svg" /></span>
        <span class="title">{{ $t("homePage") }}</span>
      </router-link>
    </li> -->
    <li>
      <router-link
        to="/employees"
        :class="currentPage == 'employees' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/employees.svg" /></span>
        <span class="title">{{ $t("theEmployees") }}</span>
      </router-link>
    </li>
    <li class="sub-menu">
      <a
        v-b-toggle.attendance
        :class="currentPage == 'attendance' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/attendance.svg" /></span>
        <span class="title">{{ $t("attendanceAndDeparture") }}</span>
      </a>
      <b-collapse id="attendance">
        <ul>
          <li>
            <router-link
              to="/add-attendance"
              :class="currentSubPage == 'add-attendance' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/add-attendance.svg"
              /></span>
              <span class="title">{{ $t("checkIn") }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/add-departure"
              :class="currentSubPage == 'add-departure' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/add-departure.svg"
              /></span>
              <span class="title">{{ $t("checkOut") }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/attendance-history"
              :class="currentSubPage == 'attendance-history' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/attendanceHistory.svg"
              /></span>
              <span class="title">{{
                $t("attendanceAndDepartureHistory")
              }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>
    <li>
      <router-link
        to="/points"
        :class="currentPage == 'points' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/points.svg" /></span>
        <span class="title">{{ $t("points") }}</span>
      </router-link>
    </li>
    <li>
      <router-link
        to="/reports"
        :class="currentPage == 'reports' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/reports.svg" /></span>
        <span class="title">{{ $t("reports") }}</span>
      </router-link>
    </li>
    <li>
      <router-link
        to="/privilege"
        :class="currentPage == 'privilege' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/privilege.svg" /></span>
        <span class="title">{{ $t("privilege") }}</span>
      </router-link>
    </li>
    <li class="sub-menu">
      <a v-b-toggle.settings :class="currentPage == 'settings' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/settings.svg" /></span>
        <span class="title">{{ $t("settings") }}</span>
      </a>
      <b-collapse id="settings">
        <ul>
          <li>
            <router-link
              to="/settings-general"
              :class="currentSubPage == 'settings-general' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/settings-general.svg"
              /></span>
              <span class="title">{{ $t("settings-general") }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/settings-additional"
              :class="currentSubPage == 'settings-additional' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/settings-additional.svg"
              /></span>
              <span class="title">{{ $t("settings-additional") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      currentPage: "",
      currentSubPage: "",
    };
  },
  methods: {
    getCurrentPage(path) {
      if (path == "/") this.currentPage = "home";
      else if (
        path.indexOf("/employee-slices") == 0 ||
        path.indexOf("/add-employee-slice") == 0
      ) {
        this.currentPage = "employeeSlices";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/employees") == 0 ||
        path.indexOf("/add-employee") == 0 ||
        path.indexOf("/edit-employee") == 0
      ) {
        this.currentPage = "employees";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/attendance-employee") == 0 ||
        path.indexOf("/add-attendance-employee") == 0 ||
        path.indexOf("/edit-attendance-employee") == 0
      ) {
        this.currentPage = "employeeAttendance";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/movement-employee") == 0 ||
        path.indexOf("/add-movement-employee") == 0 ||
        path.indexOf("/edit-movement-employee") == 0
      ) {
        this.currentPage = "employeeMovement";
        this.currentSubPage = "";
      } else if (path.indexOf("/employee-movement-media") == 0) {
        this.currentPage = "employeeMovementMedia";
        this.currentSubPage = "";
      } else if (path.indexOf("/attendance-history") == 0) {
        this.currentPage = "attendance";
        this.currentSubPage = "attendance-history";
      } else if (path.indexOf("/add-attendance") == 0) {
        this.currentPage = "attendance";
        this.currentSubPage = "add-attendance";
      } else if (path.indexOf("/add-departure") == 0) {
        this.currentPage = "attendance";
        this.currentSubPage = "add-departure";
      } else if (path.indexOf("/points") == 0) {
        this.currentPage = "points";
        this.currentSubPage = "";
      } else if (path.indexOf("/employee-points") == 0) {
        this.currentPage = "employeePoints";
        this.currentSubPage = "";
      } else if (path.indexOf("/reports") == 0) {
        this.currentPage = "reports";
        this.currentSubPage = "";
      } else if (path.indexOf("/privilege") == 0) {
        this.currentPage = "privilege";
        this.currentSubPage = "";
      } else if (path.indexOf("/settings-general") == 0) {
        this.currentPage = "settings";
        this.currentSubPage = "settings-general";
      } else if (
        path.indexOf("/settings-additional") == 0 ||
        path.indexOf("/sectors") == 0 ||
        path.indexOf("/jobs") == 0 ||
        path.indexOf("/qualifications") == 0
      ) {
        this.currentPage = "settings";
        this.currentSubPage = "settings-additional";
      }
    },
  },
  watch: {
    $route(to) {
      this.getCurrentPage(to.path);
    },
  },
  async created() {
    this.getCurrentPage(this.$route.path);
  },
};
</script>

<style lang="scss"></style>
