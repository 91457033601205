var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg}}):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('label',[_vm._v(_vm._s(_vm.$t("dataOfAttendance")))])]),_c('div',{staticClass:"form-label-group col-md-6"},[_c('b-form-datepicker',_vm._b({staticClass:"form-control",attrs:{"date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              weekday: 'short',
            },"placeholder":_vm.$t('date'),"locale":_vm.language,"today-button":"","reset-button":""},model:{value:(_vm.employeeData.attendedDate),callback:function ($$v) {_vm.$set(_vm.employeeData, "attendedDate", $$v)},expression:"employeeData.attendedDate"}},'b-form-datepicker',_vm.labels[_vm.language] || {},false))],1),_c('div',{staticClass:"form-label-group col-md-6"},[_c('b-form-timepicker',_vm._b({attrs:{"placeholder":_vm.$t('time'),"locale":_vm.language,"now-button":"","reset-button":"","no-close-button":""},model:{value:(_vm.employeeData.attendedTime),callback:function ($$v) {_vm.$set(_vm.employeeData, "attendedTime", $$v)},expression:"employeeData.attendedTime"}},'b-form-timepicker',_vm.labels[_vm.language] || {},false))],1),_c('TextArea',{attrs:{"className":'col-12',"id":'attendedNote',"value":_vm.employeeData.attendedNote,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.employeeData.attendedNote = $event}}}),_c('div',{staticClass:"col-12 text-center"},[_c('label',[_vm._v(_vm._s(_vm.$t("dataOfDeparture")))])]),_c('div',{staticClass:"form-label-group col-md-6"},[_c('b-form-datepicker',_vm._b({staticClass:"form-control",attrs:{"date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              weekday: 'short',
            },"placeholder":_vm.$t('date'),"locale":_vm.language,"today-button":"","reset-button":""},model:{value:(_vm.employeeData.departureDate),callback:function ($$v) {_vm.$set(_vm.employeeData, "departureDate", $$v)},expression:"employeeData.departureDate"}},'b-form-datepicker',_vm.labels[_vm.language] || {},false))],1),_c('div',{staticClass:"form-label-group col-md-6"},[_c('b-form-timepicker',_vm._b({attrs:{"placeholder":_vm.$t('time'),"locale":_vm.language,"now-button":"","reset-button":"","no-close-button":""},model:{value:(_vm.employeeData.departureTime),callback:function ($$v) {_vm.$set(_vm.employeeData, "departureTime", $$v)},expression:"employeeData.departureTime"}},'b-form-timepicker',_vm.labels[_vm.language] || {},false))],1),_c('TextArea',{attrs:{"className":'col-12',"id":'departureNote',"value":_vm.employeeData.departureNote,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.employeeData.departureNote = $event}}})],1)]),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addOrUpdateEmployeeAttendance.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.submitName)+" ")]),_c('router-link',{staticClass:"btn btn-cancel",attrs:{"to":'/attendance-employee/' + _vm.employeeData.employeeToken}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }