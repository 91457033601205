<template>
  <div class="">
    <div class="reports-cards">
      <div class="reports-card">
        <router-link to="/sectors" class="">
          <img src="@/assets/images/sectors.svg" />{{ $t("sectors") }}
        </router-link>
      </div>

      <div class="reports-card">
        <router-link to="/jobs" class="">
          <img src="@/assets/images/jobs.svg" />{{ $t("jobs") }}
        </router-link>
      </div>

      <div class="reports-card">
        <router-link to="/qualifications" class="">
          <img src="@/assets/images/qualifications.svg" />
          {{ $t("qualifications") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsAdditional",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
