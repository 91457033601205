import { render, staticRenderFns } from "./SectorsDelete.vue?vue&type=template&id=52485897&scoped=true&"
import script from "./SectorsDelete.vue?vue&type=script&lang=js&"
export * from "./SectorsDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52485897",
  null
  
)

export default component.exports