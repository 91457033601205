import { render, staticRenderFns } from "./UsersPirivilegeEmployeesMoveSlides.vue?vue&type=template&id=00e8da1d&scoped=true&"
import script from "./UsersPirivilegeEmployeesMoveSlides.vue?vue&type=script&lang=js&"
export * from "./UsersPirivilegeEmployeesMoveSlides.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e8da1d",
  null
  
)

export default component.exports