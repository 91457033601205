<template>
  <b-modal id="EmployeeInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/employees.svg" class="icon-lg" />
        {{ $t("employeeData") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeNameAr"
        :title="$t('employeeNameAr')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeNameEn"
        :title="$t('employeeNameEn')"
        :imgName="'man.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeUserName"
        :title="$t('userName')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeEmail"
        :title="$t('email')"
        :imgName="'email.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeePhone"
        :title="$t('phoneNumber')"
        :imgName="'phone.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeBirthdate"
        :title="$t('birthdate')"
        :imgName="'date.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          setDataMultiLang(
            language,
            employeeData.employeeGenderNameAr,
            employeeData.employeeGenderNameEn
          )
        "
        :title="$t('gender')"
        :imgName="'gender.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          setDataMultiLang(
            language,
            employeeData.qualificationNameAr,
            employeeData.qualificationNameEn
          )
        "
        :title="$t('qualificationName')"
        :imgName="'qualifications.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          setDataMultiLang(
            language,
            employeeData.sectorNameAr,
            employeeData.sectorNameEn
          )
        "
        :title="$t('sectorName')"
        :imgName="'sectors.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          setDataMultiLang(
            language,
            employeeData.jobNameAr,
            employeeData.jobNameEn
          )
        "
        :title="$t('jobName')"
        :imgName="'jobs.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeNationalNumber"
        :title="$t('nationalNumber')"
        :imgName="'nationalNumber.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeNationalNumberExpireDate"
        :title="$t('nationalNumberExpireDate')"
        :imgName="'date.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeDrivingLicenceNumber"
        :title="$t('drivingLicenceNumber')"
        :imgName="'drivingLicense.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeDrivingLicenceExpireDate"
        :title="$t('drivingLicenceExpireDate')"
        :imgName="'date.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeePassportNumber"
        :title="$t('passportNumber')"
        :imgName="'passportNumber.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeePassportExpireDate"
        :title="$t('passportExpireDate')"
        :imgName="'date.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          setDataMultiLang(
            language,
            employeeData.employeeMaritalNameAr,
            employeeData.employeeMaritalNameEn
          )
        "
        :title="$t('maritalStatus')"
        :imgName="'maritalStatus.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeMaritalStatusNotes"
        :title="$t('maritalStatusNotes')"
        :imgName="'notes.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          setDataMultiLang(
            language,
            employeeData.employeeMilitaryNameAr,
            employeeData.employeeMilitaryNameEn
          )
        "
        :title="$t('militaryStatus')"
        :imgName="'militaryStatus.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeMilitaryStatusNotes"
        :title="$t('militaryStatusNotes')"
        :imgName="'notes.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          setDataMultiLang(
            language,
            employeeData.employeeTypeNameAr,
            employeeData.employeeTypeNameEn
          )
        "
        :title="$t('employeeType')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeNotes"
        :title="$t('employeeNotes')"
        :imgName="'notes.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeAddressAr"
        :title="$t('employeeAddressAr')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeAddressEn"
        :title="$t('employeeAddressEn')"
        :imgName="'address.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="timeToLang(employeeData.employeeStartWorkTime)"
        :title="$t('startWorkTime')"
        :imgName="'time.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="timeToLang(employeeData.employeeEndWorkTime)"
        :title="$t('endWorkTime')"
        :imgName="'time.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { timeToLang } from "@/utils/functions";

export default {
  name: "EmployeeInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["employeeData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
};
</script>

<style scoped lang="scss"></style>
