var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"EmployeeInfo","scrollable":"","size":"lg","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/employees.svg")}}),_vm._v(" "+_vm._s(_vm.$t("employeeData"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeNameAr,"title":_vm.$t('employeeNameAr'),"imgName":'man.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeNameEn,"title":_vm.$t('employeeNameEn'),"imgName":'man.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeUserName,"title":_vm.$t('userName'),"imgName":'man.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeEmail,"title":_vm.$t('email'),"imgName":'email.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeePhone,"title":_vm.$t('phoneNumber'),"imgName":'phone.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeBirthdate,"title":_vm.$t('birthdate'),"imgName":'date.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.setDataMultiLang(
          _vm.language,
          _vm.employeeData.employeeGenderNameAr,
          _vm.employeeData.employeeGenderNameEn
        ),"title":_vm.$t('gender'),"imgName":'gender.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.setDataMultiLang(
          _vm.language,
          _vm.employeeData.qualificationNameAr,
          _vm.employeeData.qualificationNameEn
        ),"title":_vm.$t('qualificationName'),"imgName":'qualifications.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.setDataMultiLang(
          _vm.language,
          _vm.employeeData.sectorNameAr,
          _vm.employeeData.sectorNameEn
        ),"title":_vm.$t('sectorName'),"imgName":'sectors.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.setDataMultiLang(
          _vm.language,
          _vm.employeeData.jobNameAr,
          _vm.employeeData.jobNameEn
        ),"title":_vm.$t('jobName'),"imgName":'jobs.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeNationalNumber,"title":_vm.$t('nationalNumber'),"imgName":'nationalNumber.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeNationalNumberExpireDate,"title":_vm.$t('nationalNumberExpireDate'),"imgName":'date.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeDrivingLicenceNumber,"title":_vm.$t('drivingLicenceNumber'),"imgName":'drivingLicense.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeDrivingLicenceExpireDate,"title":_vm.$t('drivingLicenceExpireDate'),"imgName":'date.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeePassportNumber,"title":_vm.$t('passportNumber'),"imgName":'passportNumber.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeePassportExpireDate,"title":_vm.$t('passportExpireDate'),"imgName":'date.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.setDataMultiLang(
          _vm.language,
          _vm.employeeData.employeeMaritalNameAr,
          _vm.employeeData.employeeMaritalNameEn
        ),"title":_vm.$t('maritalStatus'),"imgName":'maritalStatus.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeMaritalStatusNotes,"title":_vm.$t('maritalStatusNotes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.setDataMultiLang(
          _vm.language,
          _vm.employeeData.employeeMilitaryNameAr,
          _vm.employeeData.employeeMilitaryNameEn
        ),"title":_vm.$t('militaryStatus'),"imgName":'militaryStatus.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeMilitaryStatusNotes,"title":_vm.$t('militaryStatusNotes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.setDataMultiLang(
          _vm.language,
          _vm.employeeData.employeeTypeNameAr,
          _vm.employeeData.employeeTypeNameEn
        ),"title":_vm.$t('employeeType'),"imgName":'man.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeNotes,"title":_vm.$t('employeeNotes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeAddressAr,"title":_vm.$t('employeeAddressAr'),"imgName":'address.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeData.employeeAddressEn,"title":_vm.$t('employeeAddressEn'),"imgName":'address.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.timeToLang(_vm.employeeData.employeeStartWorkTime),"title":_vm.$t('startWorkTime'),"imgName":'time.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.timeToLang(_vm.employeeData.employeeEndWorkTime),"title":_vm.$t('endWorkTime'),"imgName":'time.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }