<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <EmployeeForm
      v-if="!isLoading"
      :employeeData="employeeData"
      :imageSrc="imageSrc"
      :submitName="$t('edit')"
      v-on:addOrUpdateEmployeeWithoutImage="updateEmployeeWithoutImage"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS, BASE_URL, DEFAULT_IMG } from "@/utils/constants";
import { date2slash, date2dash, timeTo12, timeTo24 } from "@/utils/functions";
import axios from "axios";
import EmployeeForm from "@/components/employees/EmployeeForm.vue";

export default {
  name: "EmployeeEdit",
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeForm,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeToken: this.$route.params.employeeToken,
      imageSrc: DEFAULT_IMG,
      employeeData: {},
    };
  },
  methods: {
    async getEmployeeDetails() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `/Employees/GetEmployeeDetails?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&employeeToken=${this.employeeToken}`
        );
        if (response.data.status == STATUS.SUCCESS) {
          const theData = response.data.employeeData;
          this.employeeData = theData;
          this.employeeData.employeeBirthdate = date2dash(
            theData.employeeBirthdate
          );
          this.employeeData.employeeNationalNumberExpireDate = date2dash(
            theData.employeeNationalNumberExpireDate
          );
          this.employeeData.employeeDrivingLicenceExpireDate = date2dash(
            theData.employeeDrivingLicenceExpireDate
          );
          this.employeeData.employeePassportExpireDate = date2dash(
            theData.employeePassportExpireDate
          );
          this.employeeData.employeeCCWithCCName =
            theData.employeeCountryCodeName
              ? theData.employeeCountryCodeName +
                " " +
                theData.employeeCountryCode
              : "";
          this.employeeData.employeeStartWorkTime = timeTo24(
            theData.employeeStartWorkTime
          );
          this.employeeData.employeeEndWorkTime = timeTo24(
            theData.employeeEndWorkTime
          );
          this.employeeData.image = "";
          this.imageSrc = BASE_URL + theData.employeeImagePath;
          // this.employeeData.imageSrc = "";
          // this.employeeData.imageSrc = BASE_URL + theData.employeeImagePath;
          // this.employeeData.imageSrc = DEFAULT_IMG;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeesData = null;
      }
      this.isLoading = false;
    },
    async updateEmployeeWithoutImage() {
      this.isLoading = true;
      if (
        this.employeeData.image == "" ||
        this.employeeData.image == undefined
      ) {
        var data = {
          image: this.employeeData.image,
          language: this.language,
          userAuthorizeToken: this.userAuthorizeToken,
          employeeToken: this.employeeToken,
          employeeNameEn: this.employeeData.employeeNameEn,
          employeeNameAr: this.employeeData.employeeNameAr,
          employeeCode: this.employeeData.employeeCode,
          employeeCountryCodeName: this.employeeData.employeeCountryCodeName,
          employeeCountryCode: this.employeeData.employeeCountryCode,
          employeePhone: this.employeeData.employeePhone,
          employeeUserName: this.employeeData.employeeUserName,
          employeeEmail: this.employeeData.employeeEmail,
          employeeBirthdate: date2slash(this.employeeData.employeeBirthdate),
          employeeOneKilometerPrice:
            this.employeeData.employeeOneKilometerPrice,
          employeeAddressEn: this.employeeData.employeeAddressEn,
          employeeAddressAr: this.employeeData.employeeAddressAr,
          employeeGenderToken: this.employeeData.employeeGenderToken,
          employeeSectorToken: this.employeeData.employeeSectorToken,
          employeeJobToken: this.employeeData.employeeJobToken,
          employeeQualificationToken:
            this.employeeData.employeeQualificationToken,
          employeeNationalNumber: this.employeeData.employeeNationalNumber,
          employeeNationalNumberExpireDate: date2slash(
            this.employeeData.employeeNationalNumberExpireDate
          ),
          employeeDrivingLicenceNumber:
            this.employeeData.employeeDrivingLicenceNumber,
          employeeDrivingLicenceExpireDate: date2slash(
            this.employeeData.employeeDrivingLicenceExpireDate
          ),
          employeePassportNumber: this.employeeData.employeePassportNumber,
          employeePassportExpireDate: date2slash(
            this.employeeData.employeePassportExpireDate
          ),
          employeeMaritalToken: this.employeeData.employeeMaritalToken,
          employeeMaritalStatusNotes:
            this.employeeData.employeeMaritalStatusNotes,
          employeeMilitaryToken: this.employeeData.employeeMilitaryToken,
          employeeMilitaryStatusNotes:
            this.employeeData.employeeMilitaryStatusNotes,
          employeeTypeToken: this.employeeData.employeeTypeToken,
          employeeNotes: this.employeeData.employeeNotes,
          employeeStartWorkTime: timeTo12(
            this.employeeData.employeeStartWorkTime
          ),
          employeeEndWorkTime: timeTo12(this.employeeData.employeeEndWorkTime),
        };
        console.log(data);
      } else {
        var formData = new FormData();
        formData.append("image", this.employeeData.image);
        formData.append("language", this.language);
        formData.append("userAuthorizeToken", this.userAuthorizeToken);
        formData.append("employeeToken", this.employeeToken);
        formData.append("employeeNameEn", this.employeeData.employeeNameEn);
        formData.append("employeeNameAr", this.employeeData.employeeNameAr);
        formData.append("employeeCode", this.employeeData.employeeCode);
        formData.append(
          "employeeCountryCodeName",
          this.employeeData.employeeCountryCodeName
        );
        formData.append(
          "employeeCountryCode",
          this.employeeData.employeeCountryCode
        );
        formData.append("employeePhone", this.employeeData.employeePhone);
        formData.append("employeeUserName", this.employeeData.employeeUserName);
        formData.append("employeeEmail", this.employeeData.employeeEmail);
        formData.append(
          "employeeBirthdate",
          date2slash(this.employeeData.employeeBirthdate)
        );
        formData.append("employeeHourPrice", "");
        formData.append(
          "employeeAddressEn",
          this.employeeData.employeeAddressEn
        );
        formData.append(
          "employeeAddressAr",
          this.employeeData.employeeAddressAr
        );
        formData.append(
          "employeeGenderToken",
          this.employeeData.employeeGenderToken
        );
        formData.append("employeeJobToken", this.employeeData.employeeJobToken);
        formData.append("employeeCountryToken", "");
        formData.append("employeeGovernateToken", "");
        formData.append("employeeCityToken", "");
        formData.append("employeeDistrictToken", "");
        formData.append(
          "employeeQualificationToken",
          this.employeeData.employeeQualificationToken
        );
        formData.append("employeeBankToken", "");
        formData.append(
          "employeeNationalNumber",
          this.employeeData.employeeNationalNumber
        );
        formData.append(
          "employeeNationalNumberExpireDate",
          date2slash(this.employeeData.employeeNationalNumberExpireDate)
        );
        formData.append(
          "employeeDrivingLicenceNumber",
          this.employeeData.employeeDrivingLicenceNumber
        );
        formData.append(
          "employeeDrivingLicenceExpireDate",
          date2slash(this.employeeData.employeeDrivingLicenceExpireDate)
        );
        formData.append(
          "employeePassportNumber",
          this.employeeData.employeePassportNumber
        );
        formData.append(
          "employeePassportExpireDate",
          date2slash(this.employeeData.employeePassportExpireDate)
        );
        formData.append(
          "employeeMaritalToken",
          this.employeeData.employeeMaritalToken
        );
        formData.append(
          "employeeMilitaryToken",
          this.employeeData.employeeMilitaryToken
        );
        formData.append(
          "employeeMaritalStatusNotes",
          this.employeeData.employeeMaritalStatusNotes
        );
        formData.append(
          "employeeMilitaryStatusNotes",
          this.employeeData.employeeMilitaryStatusNotes
        );
        formData.append("employeeContractStartDate", "");
        formData.append("employeeContractEndDate", "");
        formData.append("employeeCitizenShipTypeToken", "");
        formData.append("employeeResidencyNumber", "");
        formData.append("employeeResidencyStartDate", "");
        formData.append("employeeResidencyEndDate", "");
        formData.append("employeeNotes", this.employeeData.employeeNotes);
        formData.append(
          "employeeTypeToken",
          this.employeeData.employeeTypeToken
        );
        formData.append(
          "employeeStartWorkTime",
          timeTo12(this.employeeData.employeeStartWorkTime)
        );
        formData.append(
          "employeeEndWorkTime",
          timeTo12(this.employeeData.employeeEndWorkTime)
        );
        formData.append("employeeActiveStatus", "");
      }

      try {
        let response = "";
        if (
          this.employeeData.image == "" ||
          this.employeeData.image == undefined
        ) {
          response = await axios.post(
            `/Employees/UpdateEmployeeWithoutImage`,
            data
          );
        } else {
          response = await axios.post(
            `/Employees/UpdateEmployeeWithImage`,
            formData
          );
        }
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push("/employees");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    showMsg(msg = "", isSuccess = false) {
      let variant = isSuccess ? "success" : "danger";
      this.$bvToast.toast(`${msg}`, {
        title: this.$t("alert"),
        variant: variant,
        toaster: "b-toaster-bottom-center",
        autoHideDelay: 5000,
      });
    },
  },
  async created() {
    this.getEmployeeDetails();
  },
};
</script>

<style scoped lang="scss"></style>
