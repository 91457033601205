<template>
  <div class="">
    <ForgetPasswordForm />
  </div>
</template>

<script>
import ForgetPasswordForm from "@/components/user/ForgetPasswordForm.vue";

export default {
  name: "ForgetPassword",
  components: {
    ForgetPasswordForm,
  },
};
</script>
