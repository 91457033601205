var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.usersPirivilegeEmployeesMoveSlides",modifiers:{"usersPirivilegeEmployeesMoveSlides":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("slices"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"usersPirivilegeEmployeesMoveSlides"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesView
            ),expression:"\n              usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMoveSlidesView"},domProps:{"checked":Array.isArray(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesView
            )?_vm._i(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesView
            ,null)>-1:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesView
            )},on:{"change":function($event){var $$a=
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMoveSlidesView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesViewSelf
            ),expression:"\n              usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesViewSelf\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMoveSlidesViewSelf"},domProps:{"checked":Array.isArray(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesViewSelf
            )?_vm._i(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesViewSelf
            ,null)>-1:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesViewSelf
            )},on:{"change":function($event){var $$a=
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesViewSelf
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesViewSelf", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesViewSelf", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesViewSelf", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMoveSlidesViewSelf"}},[_vm._v(_vm._s(_vm.$t("viewSelf")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesAdd
            ),expression:"\n              usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMoveSlidesAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesAdd
            )?_vm._i(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesAdd
            ,null)>-1:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMoveSlidesAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesUpdate
            ),expression:"\n              usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMoveSlidesUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesUpdate
            )?_vm._i(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesUpdate
            ,null)>-1:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMoveSlidesUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesArchive
            ),expression:"\n              usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMoveSlidesArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesArchive
            )?_vm._i(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesArchive
            ,null)>-1:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMoveSlidesArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesDelete
            ),expression:"\n              usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMoveSlidesDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesDelete
            )?_vm._i(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesDelete
            ,null)>-1:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMoveSlidesDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesRestore
            ),expression:"\n              usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMoveSlidesRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesRestore
            )?_vm._i(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesRestore
            ,null)>-1:(
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPirivilegeEmployeesMoveSlides.employeesMoveSlidesRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPirivilegeEmployeesMoveSlides, "employeesMoveSlidesRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMoveSlidesRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }